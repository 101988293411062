export const MAILER_URL = "https://mailer.qcfs.biz";
export const QCFS_VERSION = "2.0.2.22";
export const QCFS_CLUSTER = "azr2024";
export const QCFS_CLUSTER_NODE = "edddb5415050";
export const INDEX_API_URL = "https://indexapi.qcfs.biz";
export const MANIFEST_API_URL = "https://manifestapi.qcfs.biz";
export const MOVEMENT_API_URL = "https://movementapi.qcfs.biz";
export const MISC_API_URL = "https://miscapi.qcfs.biz";

export default {
    QCFS_CLUSTER,
    QCFS_CLUSTER_NODE,
    QCFS_VERSION,
    MISC_API_URL,
    MAILER_URL,
    MOVEMENT_API_URL,
    INDEX_API_URL,
    MANIFEST_API_URL
}